export const ErrorMessage = {
  ValidationError:
    'Looks like the request contained invalid fields. Please review the details section to remedy this problem',
  AuthenticationError: 'Oops, looks like we cannot find matching credentials for this user',
  IdleAuthenticationError: "Oops, looks like you've been inactive for too long and your login session has expired",
  UnauthorizedError: 'Sorry, you must be logged in to do this',
  NotFoundError: 'We could not find the requested resource',
  NotFoundOrExpiredError: 'Token may be expired or invalid',
  UnknownError:
    "A wild error appeared! Sorry, we don't know how this happened Try again or contact us if this issue persists",
  SSOConfigurationError: 'No SAML SSO enabled workspace could be found',
  VerifyDomainError: "Failed to verify domain Please make sure you've added the given DNS TXT record",
  UnavailableDomainError: 'The provided domain cannot be used for SAML SSO setup',
  VerifyUserError: 'Your account verification failed',
  PasswordUsedError: "You can't use the current password",
  NotAllowedError: 'You cannot detele this resource',
  ForbiddenError: 'You are not allowed to access this resource',
  InvalidRoles: 'Roles must exist in the workspace in order to assign them',
  InvalidGroups: 'Groups must exist in the workspace in order to assign them',
  InvalidResponseFormat:
    'The server processed your response but the response was different than the expected format. Please contact the responsible developer to fix this issue',
  NotImplemented: 'This feature is not yet implemented',
  PaginationError: 'There was a problem with the pagination details',
  AlreadyExistsError: 'A resource with the given identifier already exists',
  GroupScopeError: 'Group scope must be the same as parent group scope',
  GroupDeleteError: 'The group could not be deleted because it has child resources',
  GroupMoveError: 'A shortcut resource cannot be moved to a system group',
  NoPricingModelError: "The specified campaign doesn't have a pricing model template",
  NoPricingModelTypeError: 'The current workspace does not have a specified pricing model type',
  NoDefaultPricingModelError: 'There is no default pricing model template defined for the current workspace',
  PricingModelUpdateError: 'Only upcoming pricing models can pe updated',
  InvalidPriceError: 'Looks like one of the prices is invalid. Please review your changes',
  NoCampaignError:
    "A pricing model cannot be created for the specified organization because it's not part of any campaign.",
  InvalidAdditionalServiceError: 'Looks like one of the additional services cannot be set. Please review your changes',
  InvalidGBError: 'The specified data volume (in GB) appears to be invalid. Please review your changes',
  InvalidPackageError:
    'The specified packages interval (min-max weight) appears to be invalid. Please review your changes',
  UpdateWorkspacePricingModelError: 'The workspace pricing model type cannot be updated while being in use',
  DocumentConversionError: 'The conversion of the document failed',
  DocumentRetrievalError: 'Unable to find / access the required document',
  DocumentUploadError: 'Unable to upload the document',
  EmailAccountAlreadyUsed: 'Email account already attached',
  InvalidWorkspaceError: 'Invalid Workspace provided',
  IMAPEmailAuthenticationError: 'Oops, looks like we cannot authenticate the IMAP email account',
  EWSEmailAuthenticationError: 'Oops, looks like we cannot authenticate the EWS email account',
  DocumentsNotReady: 'The documents to be processed contain at least 1 item in pending / error state',
  MissingDependencies: "The action failed to execute because some of it's dependencies are missing / failed to fetch",
  DocumentCreationError: 'Creating the document failed',
  EwsCredentialsFailedError: 'Fetch EWS Client Credentials Failed',
  CustomerAccountNotValidatedError: 'Account not validated',
  EmailAlreadyUsedError: 'Email already used',
  CustomerDataTokenUsedError: 'Customer Data Token already used',
  AddressBookAssignmentNotAllowed: 'Contacts can be created only inside groups or organisations.',
  MissingFolderError: 'Folder does not exist or item is not a folder.',
  MissingEmailError: 'Email is required to invite person to workspace.',
} as const
