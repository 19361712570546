<script setup lang="ts">
import { onBeforeUnmount, ref, watch } from 'vue'

import { Button } from '@epostbox/ui/button'
import { Paragraph } from '@epostbox/ui/paragraph'
import { SideDrawer } from '@epostbox/ui/side-drawer'
import { StatusDot } from '@epostbox/ui/status-dot'

import { useEmailAccounts } from '@composables/email-integration/use-email-accounts'
import { useSideDrawer } from '@composables/use-sidedrawer'

import EmailIntegrationFlow from './email-integration-flow.vue'

const { closeEmailSideDrawer } = useSideDrawer()

withDefaults(
  defineProps<{
    title?: string
  }>(),
  {
    title: '',
  }
)

onBeforeUnmount(() => {
  closeEmailSideDrawer()
})

const { emailAccounts } = useEmailAccounts()
const showAddFlow = ref(false)

const statusDot = (protocol: string) => {
  switch (protocol) {
    case 'imap': {
      return 'success'
    }
    case 'ews': {
      return 'info'
    }
    default: {
      return 'error'
    }
  }
}

const openEmail = defineModel<boolean>('openEmail', { default: false })

watch(openEmail, value => {
  if (value) {
    showAddFlow.value = false
  }
})
</script>

<template>
  <SideDrawer v-model:open="openEmail" :title="title" @close="closeEmailSideDrawer">
    <div class="container mx-auto">
      <div v-if="!showAddFlow">
        <Paragraph class="mb-2">{{ $t('auth.common.email_accounts') }}</Paragraph>

        <ul v-if="emailAccounts && emailAccounts.length > 0" class="mb-3 rounded-lg bg-white">
          <li v-for="account in emailAccounts" :key="account?.id">
            <div class="flex items-center gap-3 p-3">
              <StatusDot size="sm" :status="statusDot(account.protocol)" />
              <span class="text-md text-gray-900">{{ account.email }}</span>
            </div>
            <hr
              v-if="emailAccounts && emailAccounts.indexOf(account) !== emailAccounts.length - 1"
              class="border-t-2"
            />
          </li>
        </ul>
        <p v-else class="mb-3 text-gray-500">{{ $t('auth.common.no_email_accounts_added') }}</p>

        <Button variant="link" class="text-brand-550" @click="showAddFlow = true">
          <INolasPlus class="h-5 w-5" /> {{ $t('auth.common.add_email_account') }}
        </Button>
      </div>

      <EmailIntegrationFlow v-else @go-back="showAddFlow = false" />
    </div>
  </SideDrawer>
</template>
