import { ref, type Component } from 'vue'
import { useI18n } from 'vue-i18n'

const open = ref<boolean>(false)
const showTabs = ref<boolean>(true)
const openEmail = ref(false)

export interface Section {
  title: string
  name: string
  value: string
  description?: string
  tooltip?: string
  tooltipCategory?: string
  component: Component
}

export interface Tab {
  value: string
  label: string
  children?: Omit<Tab, 'children'>[]
}

export const useSideDrawer = () => {
  const { t } = useI18n()

  const tabsSignatures = [
    {
      value: 'general-data',
      label: t('app.crm.organizations.tabs.general_data'),
    },
    {
      value: 'workflows',
      label: t('app.keyflowz.documents.quick_filters.workflows'),
    },
  ]

  const tabsLetterheads = [
    {
      value: 'general-data',
      label: t('app.crm.organizations.tabs.general_data'),
    },
    {
      value: 'workflows',
      label: t('app.keyflowz.documents.quick_filters.workflows'),
    },
  ]

  const textTemplatesTabs = [
    {
      value: 'general-data',
      label: t('app.keyflowz.text_templates.tabs.general_data'),
    },
    {
      value: 'workflows',
      label: t('app.keyflowz.text_templates.tabs.workflows'),
    },
  ]

  const tabsAttachments = [
    {
      value: 'general-data',
      label: t('app.crm.organizations.tabs.general_data'),
    },
    {
      value: 'workflows',
      label: t('app.keyflowz.documents.quick_filters.workflows'),
    },
  ]

  const regions = [
    {
      label: 'Baden-Württemberg',
      value: 'baden-württemberg',
    },
    {
      label: 'Bayern',
      value: 'bayern',
    },
    {
      label: 'Berlin',
      value: 'berlin',
    },
    {
      label: 'Brandenburg',
      value: 'brandenburg',
    },
    {
      label: 'Bremen',
      value: 'bremen',
    },
    {
      label: 'Hamburg',
      value: 'hamburg',
    },
    {
      label: 'Hessen',
      value: 'hessen',
    },
    {
      label: 'Mecklenburg-Vorpommern',
      value: 'mecklenburg-vorpommern',
    },
    {
      label: 'Niedersachsen',
      value: 'niedersachsen',
    },
    {
      label: 'Nordrhein-Westfalen',
      value: 'nordrhein-westfalen',
    },
    {
      label: 'Rheinland-Pfalz',
      value: 'rheinland-pfalz',
    },
    {
      label: 'Saarland',
      value: 'saarland',
    },
    {
      label: 'Sachsen',
      value: 'sachsen',
    },
    {
      label: 'Sachsen-Anhalt',
      value: 'sachsen-anhalt',
    },
    {
      label: 'Schleswig-Holstein',
      value: 'schleswig-holstein',
    },
    {
      label: 'Thüringen',
      value: 'thüringen',
    },
  ]

  const toggleSideDrawer = () => {
    open.value = true
  }
  const openSideDrawer = () => {
    open.value = true
  }
  const closeSideDrawer = () => {
    open.value = false
  }

  const displayTabs = (): void => {
    showTabs.value = true
  }
  const hideTabs = (): void => {
    showTabs.value = false
  }

  const openEmailSideDrawer = () => {
    openEmail.value = true
  }
  const closeEmailSideDrawer = () => {
    openEmail.value = false
  }

  return {
    open,
    openEmail,
    openSideDrawer,
    closeSideDrawer,
    openEmailSideDrawer,
    closeEmailSideDrawer,
    tabsSignatures,
    tabsLetterheads,
    textTemplatesTabs,
    tabsAttachments,
    regions,
    toggleSideDrawer,
    showTabs,
    displayTabs,
    hideTabs,
  }
}
