<script setup lang="ts">
import { onClickOutside } from '@vueuse/core'
import { debounce } from 'lodash'
import { ref, watch, computed } from 'vue'

import { cn } from '../../utils'
import { IconButton } from '../icon-button'

import { searchtWrapperVariants, searchVariants, type CustomInputProps } from '.'

defineOptions({
  inheritAttrs: false,
})

const props = withDefaults(
  defineProps<
    CustomInputProps & {
      debounceTime?: number
      hideClearButton?: boolean
      showDot?: boolean
      disabled?: boolean
      dataE2e?: string
    }
  >(),
  {
    debounceTime: 0,
    hideClearButton: false,
    showDot: true,
    disabled: false,
    dataE2e: '',
  }
)

const modelValue = defineModel<string | number>()
const open = ref<boolean>(false)
const search = ref<HTMLElement>()
const searchInput = ref<HTMLInputElement>()

const onUpdateDataDebounced = debounce((e: Event) => {
  const input = e.target as HTMLInputElement
  modelValue.value = input.value
}, props.debounceTime ?? 0)

const handleClose = () => {
  modelValue.value = ''
  open.value = false
}

const showValueDot = computed(() => {
  if (!props.showDot || open.value) return false

  return !!modelValue.value
})

watch(open, () => {
  if (open.value) searchInput.value?.focus()
})

onClickOutside(search, event => {
  if (open.value) {
    open.value = false
    event.stopPropagation()
  }
})

const handleKeyDown = (event: KeyboardEvent) => {
  if (event.key === 'Tab' && event.shiftKey) open.value = false
}

const onSearchClick = () => {
  open.value = true
  searchInput.value?.focus()
}
</script>

<template>
  <div
    ref="search"
    :class="
      cn(
        'transition-all',
        searchtWrapperVariants({ color }),
        !open && !hideClearButton ? 'w-9 cursor-pointer p-0 px-[5px]' : 'w-full cursor-text',
        disabled ? 'pointer-events-none cursor-not-allowed opacity-60' : 'opacity-100',
        $attrs.class ?? '',
        error ? 'border-error-650' : ''
      )
    "
    :data-e2e="`${dataE2e}-wrapper`"
    @click="onSearchClick"
    @blur="open = false"
    @keydown="handleKeyDown"
  >
    <div class="relative flex h-full w-full items-center gap-x-2 overflow-hidden">
      <INolasSearchMd
        :class="cn(color === 'gray' ? '!h-4 !w-4 !min-w-4 text-gray-warm-500' : '!h-6 !w-6 !min-w-6 text-gray-1000')"
      />
      <input
        :id="name"
        ref="searchInput"
        :value="modelValue"
        :type="type"
        v-bind="$attrs"
        :inputmode="inputmode"
        :placeholder="$t('common.search')"
        :default-value="defaultValue"
        :name="name"
        :autocomplete="autocomplete"
        :disabled="disabled"
        :class="cn(searchVariants({ color }), 'transition-all', disabled ? 'pointer-events-none' : '')"
        :data-e2e="dataE2e"
        @input="onUpdateDataDebounced"
        @focus="open = true"
      />
      <IconButton
        v-if="!hideClearButton"
        variant="transparent"
        class="!bg-transparent"
        size="xs"
        :data-e2e="`${dataE2e}-close-button`"
        @click.stop="handleClose"
        @focus="open = true"
        @blur="open = false"
      >
        <INolasXClose
          :class="
            cn(
              'p-px text-gray-500',
              color === 'gray' ? 'h-4 w-4 min-w-4' : 'h-6 w-6 min-w-6',
              disabled ? 'pointer-events-none' : 'cursor-pointer'
            )
          "
          tabindex="-1"
        />
      </IconButton>
    </div>
    <div
      v-if="showValueDot"
      class="pointer-events-none absolute -right-2 -top-2 flex h-[18px] w-[18px] items-center justify-center rounded-full bg-primary"
    ></div>
  </div>
</template>
