<script setup lang="ts">
import { toTypedSchema } from '@vee-validate/zod'
import { useForm } from 'vee-validate'
import { ref, computed } from 'vue'
import { useI18n } from 'vue-i18n'
import { z } from 'zod'

import { Button } from '@epostbox/ui/button'
import { FormField } from '@epostbox/ui/form'
import { Heading } from '@epostbox/ui/heading'
import { Input } from '@epostbox/ui/input'
import { Select } from '@epostbox/ui/select'

import { useImapAdd } from '@composables/email-integration/use-imap-add'
import { useImapHost } from '@composables/email-integration/use-imap-host'

const emit = defineEmits(['cancel', 'success'])

const { t } = useI18n()
type FormValues = z.infer<typeof schema>

const schema = z.object({
  email: z.string().email(),
  password: z.string().min(1, 'Password is required'),
  host: z.string().min(1, 'Host is required'),
  port: z.number().positive().int().nullable(),
  smtpHost: z.string().min(1, 'Host is required'),
  smtpPort: z.number().positive().int().nullable(),
  lastFetchDate: z.string(),
  displayName: z.string().nullable(),
})

const { addImapAccount } = useImapAdd()
const { fetchHost } = useImapHost()

const openRetrieveSince = ref(false)
const formStep = ref<'email' | 'details'>('email')
const today = new Date()
const todayFormatted = new Date().toISOString()
const lastMonth = new Date(today.setDate(today.getDate() - 30)).toISOString()
const last6Months = new Date(today.setMonth(today.getMonth() - 6)).toISOString()
const lastYear = new Date(today.setFullYear(today.getFullYear() - 1)).toISOString()

const retrieveSinceOptions = [
  {
    value: todayFormatted,
    label: t('auth.field.last_fetch_date.today'),
  },
  {
    value: lastMonth,
    label: t('auth.field.last_fetch_date.last_month'),
  },
  {
    value: last6Months,
    label: t('auth.field.last_fetch_date.last_6m'),
  },
  {
    value: lastYear,
    label: t('auth.field.last_fetch_date.last_year'),
  },
]

const form = useForm<FormValues>({
  validationSchema: toTypedSchema(schema),
  initialValues: {
    email: '',
    password: '',
    host: '',
    port: undefined,
    smtpHost: '',
    smtpPort: undefined,
    lastFetchDate: todayFormatted,
    displayName: '',
  },
})

const canProceed = computed(() => {
  if (formStep.value === 'email') {
    return !!form.values.email
  }
  return form.meta.value.valid
})

const handleContinue = async () => {
  if (!canProceed.value) return

  fetchHost(form.values.email, {
    onSuccess: data => {
      if (!data) return

      formStep.value = 'details'
      form.setFieldValue('host', data.imap_host)
      form.setFieldValue('port', data.imap_port)
      form.setFieldValue('smtpHost', data.smtp_host)
      form.setFieldValue('smtpPort', data.smtp_port)
    },
  })
}

const handleSubmit = form.handleSubmit(async values => {
  if (!canProceed.value) return

  await addImapAccount(
    {
      email: values.email,
      password: values.password,
      host: values.host,
      port: values.port,
      smtpHost: values.smtpHost,
      smtpPort: values.smtpPort,
      displayName: values.displayName || '',
      lastFetchDate: values.lastFetchDate,
    },
    {
      onSuccess: () => {
        emit('success')
      },
    }
  )
})
</script>

<template>
  <form class="space-y-4" @submit.prevent="handleSubmit">
    <Heading class="flex items-center justify-center gap-2 p-2 text-center text-md">
      <INolasMail class="h-5 w-5" /> {{ t('auth.common.add_imap') }}
    </Heading>
    <FormField v-slot="{ componentField }" name="email">
      <Input
        color="white"
        v-bind="componentField"
        :label="t('auth.field.email.label')"
        type="email"
        data-e2e="add-imap-account-email-input"
      />
    </FormField>

    <template v-if="formStep === 'details'">
      <FormField v-slot="{ componentField }" name="password">
        <Input
          color="white"
          v-bind="componentField"
          :label="t('auth.field.password.label')"
          type="password"
          data-e2e="add-imap-account-password-input"
        />
      </FormField>

      <div class="flex justify-between gap-4">
        <FormField v-slot="{ componentField }" name="host" class="!mb-0 w-2/3">
          <Input
            color="white"
            v-bind="componentField"
            :label="t('auth.field.imaphost.label')"
            data-e2e="add-imap-account-host-input"
          />
        </FormField>

        <FormField v-slot="{ componentField }" name="port" class="!mb-0 w-1/3">
          <Input
            color="white"
            v-bind="componentField"
            :label="t('auth.field.imapPort.label')"
            data-e2e="add-imap-account-port-input"
          />
        </FormField>
      </div>

      <div class="flex justify-between gap-4">
        <FormField v-slot="{ componentField }" name="smtpHost" class="!mb-0 w-2/3">
          <Input
            color="white"
            v-bind="componentField"
            :label="t('auth.field.smtpHost.label')"
            data-e2e="add-imap-account-host-input"
          />
        </FormField>

        <FormField v-slot="{ componentField }" name="smtpPort" class="!mb-0 w-1/3">
          <Input
            color="white"
            v-bind="componentField"
            :label="t('auth.field.smtpPort.label')"
            data-e2e="add-imap-account-port-input"
          />
        </FormField>
      </div>

      <FormField v-slot="{ componentField }" name="displayName">
        <Input
          color="white"
          v-bind="componentField"
          :label="t('auth.field.displayName.label')"
          data-e2e="add-imap-account-display-name-input"
        />
      </FormField>

      <FormField v-slot="{ componentField }" name="lastFetchDate">
        <Select
          v-bind="componentField"
          v-model:open="openRetrieveSince"
          color="white"
          :label="t('auth.field.last_fetch_date.label')"
          :items="retrieveSinceOptions"
          data-e2e="add-imap-account-last-fetch-date-select"
        />
      </FormField>
    </template>

    <div class="flex justify-between">
      <Button variant="outline" type="reset" @click="emit('cancel')">
        {{ t('common.cancel') }}
      </Button>
      <Button v-if="formStep === 'email'" type="button" :disabled="!canProceed" @click="handleContinue">
        {{ t('common.continue') }}
      </Button>
      <Button v-else type="submit" :disabled="!canProceed">
        {{ t('auth.common.add_account') }}
      </Button>
    </div>
  </form>
</template>
