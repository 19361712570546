<script setup lang="ts">
import { onBeforeUnmount } from 'vue'

import { DialogOverlay } from '@epostbox/ui/dialog'
import { Heading } from '@epostbox/ui/heading'
import { IconButton } from '@epostbox/ui/icon-button'
import { Sheet, SheetContent, SheetHeader, SheetClose } from '@epostbox/ui/sheet'

interface Props {
  title?: string
  resizableWidth?: number
}

withDefaults(defineProps<Props>(), {
  title: '',
  resizableWidth: 480,
})

const open = defineModel<boolean>('open', { default: false })

const emit = defineEmits<{
  close: []
  blur: []
  focus: []
}>()

const closeSideDrawer = () => {
  open.value = false
  emit('close')
}

onBeforeUnmount(() => {
  closeSideDrawer()
})
</script>

<template>
  <Sheet v-model:open="open">
    <DialogOverlay />
    <SheetContent
      as-child
      side="right"
      :resizable="['l']"
      hide-close-button
      :resizer-width="resizableWidth"
      class="border-l border-gray-warm-450 bg-gray-warm-50 outline-none"
    >
      <SheetHeader class="flex items-center gap-x-3 px-8 py-3">
        <SheetClose>
          <IconButton size="sm" variant="transparent" @click="closeSideDrawer">
            <INolasChevronRightDouble class="h-5 w-5" />
          </IconButton>
        </SheetClose>
        <Heading as="h2" size="sm" class="truncate font-medium text-gray-warm-900">
          {{ title }}
        </Heading>
      </SheetHeader>

      <div class="h-full overflow-auto">
        <slot />
      </div>
    </SheetContent>
  </Sheet>
</template>
