import { InferRequest } from '@epostbox/crm-api/client'
import { WorkbenchAPIClient, type InferResponse, type InferBody } from '@epostbox/keyflowz-api/client'

import { accessToken } from './retrieve-access-token'

const baseUrl = '__TAURI_IPC__' in window ? import.meta.env.VITE_WORKBENCH_API_URL : `${location.origin}`

const Workbench = WorkbenchAPIClient({
  baseUrl,
  credentials: 'include',
})
Workbench.use({
  async onRequest({ request }) {
    if (accessToken) {
      request.headers.set('authorization', `Bearer ${accessToken}`)
    }
    return request
  },
})

export type DocumentAssignmentID = string

export const CreateDocument = Workbench.post('/api/workbench/w/{workspaceId}/documents')
export type CreateDocument = InferResponse<typeof CreateDocument>

export const ListDocuments = Workbench.get('/api/workbench/w/{workspaceId}/documents')
export type ListDocuments = InferResponse<typeof ListDocuments>

export const GetDocument = Workbench.get('/api/workbench/w/{workspaceId}/documents/{documentAssignmentId}/v2')
export type Document = InferResponse<typeof GetDocument>

// export const GetDocument_V2 = Workbench.get('/api/workbench/w/{workspaceId}/documents/{documentAssignmentId}/v2')
// export type Document_V2 = InferResponse<typeof GetDocument>

export const DownloadDocuments = Workbench.post('/api/workbench/w/{workspaceId}/documents/download')
export type DocumentDownloadResponse = InferResponse<typeof DownloadDocuments>
export type DocumentDownloadRequest = InferRequest<typeof DownloadDocuments>

export const UpdateDocument = Workbench.put(
  '/api/workbench/w/{workspaceId}/documents/{documentAssignmentId}/properties'
)
export type UpdateDocumentRequest = InferBody<typeof UpdateDocument>

export const DeleteDocument = Workbench.delete('/api/workbench/w/{workspaceId}/documents/bulk')
export type DeleteDocumentRequest = InferBody<typeof DeleteDocument>

export const CreateSignature = Workbench.post('/api/workbench/w/{workspaceId}/signatures')
export type CreateSignature = InferResponse<typeof CreateSignature>
export type CreateSignatureBody = InferBody<typeof CreateSignature>

export const ListSignatures = Workbench.get('/api/workbench/w/{workspaceId}/signatures')
export type ListSignatures = InferResponse<typeof ListSignatures>

export const GetSignature = Workbench.get('/api/workbench/w/{workspaceId}/signatures/{signatureId}')
export type Signature = InferResponse<typeof GetSignature>

export const UpdateSignature = Workbench.put('/api/workbench/w/{workspaceId}/signatures/{signatureId}')
export type UpdateSignature = InferResponse<typeof UpdateSignature>

export const DeleteSignature = Workbench.delete('/api/workbench/w/{workspaceId}/signatures')
export type DeleteSignatureRequest = InferBody<typeof DeleteSignature>

export const CreateLetterhead = Workbench.post('/api/workbench/w/{workspaceId}/letterheads')
export type CreateLetterhead = InferResponse<typeof CreateLetterhead>
export type CreateLetterheadBody = InferBody<typeof CreateLetterhead>

export const ListLetterheads = Workbench.get('/api/workbench/w/{workspaceId}/letterheads')
export type ListLetterheads = InferResponse<typeof ListLetterheads>

export const GetLetterhead = Workbench.get('/api/workbench/w/{workspaceId}/letterheads/{letterheadId}')
export type Letterhead = InferResponse<typeof GetLetterhead>

export const UpdateLetterhead = Workbench.patch('/api/workbench/w/{workspaceId}/letterheads/{letterheadId}')
export type UpdateLetterhead = InferResponse<typeof UpdateLetterhead>

export const DeleteLetterhead = Workbench.delete('/api/workbench/w/{workspaceId}/letterheads')
export type DeleteLetterheadRequest = InferBody<typeof DeleteLetterhead>

export const CreateAttachment = Workbench.post('/api/workbench/w/{workspaceId}/attachments')
export type CreateAttachment = InferResponse<typeof CreateAttachment>
export type CreateAttachmentBody = InferBody<typeof CreateAttachment>

export const ListAttachments = Workbench.get('/api/workbench/w/{workspaceId}/attachments')
export type ListAttachments = InferResponse<typeof ListAttachments>

export const GetAttachment = Workbench.get('/api/workbench/w/{workspaceId}/attachments/{attachmentId}')
export type Attachment = InferResponse<typeof GetAttachment>

export const UpdateAttachment = Workbench.patch('/api/workbench/w/{workspaceId}/attachments/{attachmentId}')
export type UpdateAttachment = InferResponse<typeof UpdateAttachment>

export const DeleteAttachment = Workbench.delete('/api/workbench/w/{workspaceId}/attachments')
export type DeleteAttachmentRequest = InferBody<typeof DeleteAttachment>

export const GetSearchKey = Workbench.get('/api/workbench/w/{workspaceId}/search/key')
export type GetSearchKeyResponse = InferResponse<typeof GetSearchKey>

export const ListTextTemplates = Workbench.get('/api/workbench/w/{workspaceId}/text-templates')
export type ListTextTemplatesResponse = InferResponse<typeof ListTextTemplates>
export type ListTextTemplatesRequest = InferRequest<typeof ListTextTemplates>

export const CreateTextTemplate = Workbench.post('/api/workbench/w/{workspaceId}/text-templates')
export type CreateTextTemplatesResponse = InferResponse<typeof CreateTextTemplate>
export type CreateTextTemplateRequest = InferBody<typeof CreateTextTemplate>

export const GetTextTemplate = Workbench.get('/api/workbench/w/{workspaceId}/text-templates/{textTemplateId}')
export type GetTextTemplateResponse = InferResponse<typeof GetTextTemplate>

export const UpdateTextTemplate = Workbench.patch('/api/workbench/w/{workspaceId}/text-templates/{textTemplateId}')
export type UpdateTextTemplateResponse = InferResponse<typeof UpdateTextTemplate>
export type UpdateTextTemplateRequest = InferBody<typeof UpdateTextTemplate>

export const DeleteTextTemplate = Workbench.delete('/api/workbench/w/{workspaceId}/text-templates/bulk')
export type DeleteTextTemplateResponse = InferResponse<typeof DeleteTextTemplate>
export type DeleteTextTemplateRequest = InferBody<typeof DeleteTextTemplate>
