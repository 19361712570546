<template>
  <div
    class="inline-block rounded-full"
    :class="[sizeClasses, colorClasses]"
    :title="title"
  ></div>
</template>

<script setup lang="ts">
import { computed } from 'vue';

interface Props {
  status: 'success' | 'warning' | 'error' | 'info' | 'inactive' | 'brand';
  size?: 'sm' | 'md' | 'lg';
  title?: string;
}

const props = withDefaults(defineProps<Props>(), {
  size: 'md',
  title: '',
});

const colorClasses = computed(() => {
  switch (props.status) {
    case 'success': {
      return 'bg-green-500';
    }
    case 'brand': {
      return 'bg-brand-500';
    }
    case 'warning': {
      return 'bg-yellow-500';
    }
    case 'error': {
      return 'bg-red-500';
    }
    case 'info': {
      return 'bg-blue-500';
    }
    case 'inactive': {
      return 'bg-gray-300';
    }
    default: {
      return 'bg-gray-500';
    }
  }
});

const sizeClasses = computed(() => {
  switch (props.size) {
    case 'sm': {
      return 'w-2 h-2';
    }
    case 'md': {
      return 'w-3 h-3';
    }
    case 'lg': {
      return 'w-4 h-4';
    }
    default: {
      return 'w-3 h-3';
    }
  }
});
</script>