<script setup lang="ts">
import { toTypedSchema } from '@vee-validate/zod'
import { useForm } from 'vee-validate'
import { ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { z } from 'zod'

import { Button } from '@epostbox/ui/button'
import { FormField } from '@epostbox/ui/form'
import { Heading } from '@epostbox/ui/heading'
import { Input } from '@epostbox/ui/input'
import { Select } from '@epostbox/ui/select'

import { useExchangeLogin } from '@composables/email-integration/use-exchange-login'

const emit = defineEmits(['cancel', 'success'])

const { t } = useI18n()

const schema = z.object({
  lastFetchDate: z.string(),
  displayName: z.string().nullable(),
})

const { fetchLoginUrl } = useExchangeLogin()

const openRetrieveSince = ref(false)
const today = new Date()
const todayFormatted = new Date().toISOString()
const lastMonth = new Date(today.setDate(today.getDate() - 30)).toISOString()
const last6Months = new Date(today.setMonth(today.getMonth() - 6)).toISOString()
const lastYear = new Date(today.setFullYear(today.getFullYear() - 1)).toISOString()

const retrieveSinceOptions = [
  {
    value: todayFormatted,
    label: t('auth.field.last_fetch_date.today'),
  },
  {
    value: lastMonth,
    label: t('auth.field.last_fetch_date.last_month'),
  },
  {
    value: last6Months,
    label: t('auth.field.last_fetch_date.last_6m'),
  },
  {
    value: lastYear,
    label: t('auth.field.last_fetch_date.last_year'),
  },
]

const form = useForm({
  validationSchema: toTypedSchema(schema),
  initialValues: {
    lastFetchDate: todayFormatted,
    displayName: '',
  },
})

const handleSubmit = form.handleSubmit(async values => {
  const url = await fetchLoginUrl({
    lastFetchDate: values.lastFetchDate,
    displayName: values.displayName,
  })
  window.open(url, '_blank')
  emit('success')
})
</script>

<template>
  <form @submit.prevent="handleSubmit">
    <Heading class="flex items-center justify-center gap-2 p-2 text-center text-md">
      <INolasExchange class="h-5 w-5" /> {{ t('auth.common.add_exchange') }}
    </Heading>

    <FormField v-slot="{ componentField }" name="displayName">
      <Input
        color="white"
        v-bind="componentField"
        :label="t('auth.field.displayName.label')"
        data-e2e="add-exchange-account-display-name-input"
      />
    </FormField>

    <FormField v-slot="{ componentField }" name="lastFetchDate">
      <Select
        v-bind="componentField"
        v-model:open="openRetrieveSince"
        color="white"
        :label="t('auth.field.last_fetch_date.label')"
        :items="retrieveSinceOptions"
        data-e2e="add-exchange-account-last-fetch-date-select"
      />
    </FormField>

    <div class="flex justify-between">
      <Button variant="outline" type="reset" @click="emit('cancel')">
        {{ t('common.cancel') }}
      </Button>

      <Button type="submit">
        {{ t('auth.common.add_account') }}
      </Button>
    </div>
  </form>
</template>
