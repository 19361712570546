import { createQueryKeys } from '@lukemorales/query-key-factory'
import { GetEmailAccounts } from '@services/auth-api'
import { useQuery } from '@tanstack/vue-query'

import { ServiceError } from '@epostbox/shared/errors'

export const emailAccountsQuery = createQueryKeys('email-accounts')

type EmailAccount = {
  id: string
  email: string
  protocol: string
  status: number
  displayName?: string
}

async function fetchEmailAccounts() {
  const { response, data, error } = await GetEmailAccounts({})

  if (!response.ok || !data) {
    throw ServiceError.fromResponse(error)
  }

  console.log(data)
  return data as EmailAccount[]
}

export function useEmailAccounts() {
  const { data: emailAccounts, error } = useQuery({
    queryKey: emailAccountsQuery._def,
    queryFn: () => fetchEmailAccounts(),
  })

  if (!emailAccounts) {
    throw ServiceError.fromResponse(error)
  }
  return { emailAccounts }
}
