import { ExchangeLogin } from '@services/auth-api'
import { useMutation, useQueryClient } from '@tanstack/vue-query'
import { ref } from 'vue'
import { useI18n } from 'vue-i18n'

import { ServiceError } from '@epostbox/shared/errors'
import { toast } from '@epostbox/ui/sonner'

import { emailAccountsQuery } from '@composables/email-integration/use-email-accounts'
import { useUser } from '@composables/use-user'

interface ExchangeLoginParams {
  lastFetchDate: string
  displayName?: string | null
}

interface ExchangeLoginResponse {
  url: string
}

export function useExchangeLogin() {
  const { user } = useUser()
  const loginUrl = ref<string | null>(null)

  const { t } = useI18n()
  const queryClient = useQueryClient()

  const { mutateAsync: getExchangeLoginUrl, error } = useMutation<
    ExchangeLoginResponse,
    ServiceError,
    ExchangeLoginParams
  >({
    mutationFn: async ({ lastFetchDate, displayName }: ExchangeLoginParams) => {
      const { response, data, error } = await ExchangeLogin({
        params: {
          query: {
            workspaceId: user.value!.workspaceId,
            lastFetchDate,
            displayName,
          },
        },
      })

      if (!response.ok || !data) {
        throw ServiceError.fromResponse(error)
      }

      return data
    },
    onSuccess: () => {
      toast.success(t('auth.common.exchange_added'), { dataE2e: 'exchange-login-success-notification' })
      const refetchTimer = setInterval(() => {
        queryClient.invalidateQueries({ queryKey: emailAccountsQuery._def })
      }, 5000)
      setTimeout(() => clearInterval(refetchTimer), 5 * 5000)
    },
    onError: () => {
      toast.error(t('auth.common.exchange_not_added'), { dataE2e: 'exchange-login-error-notification' })
    },
  })

  const fetchLoginUrl = async (params: ExchangeLoginParams): Promise<string> => {
    const data = await getExchangeLoginUrl(params)
    loginUrl.value = data.url
    return data.url
  }

  return {
    fetchLoginUrl,
    loginUrl,
    error,
  }
}
