<script setup lang="ts">
import { ref } from 'vue'
import { useI18n } from 'vue-i18n'

import { Button } from '@epostbox/ui/button'
import { Paragraph } from '@epostbox/ui/paragraph'

import ExchangeIntegrationForm from './exchange-integration-form.vue'
import ImapIntegrationForm from './imap-integration-form.vue'

const emit = defineEmits(['go-back'])

const { t } = useI18n()
const accountType = ref<'imap' | 'exchange' | null>(null)

const onCancel = () => {
  accountType.value = null
}

const onSuccess = () => {
  accountType.value = null
  emit('go-back')
}
</script>

<template>
  <div v-if="!accountType">
    <Paragraph class="p-2 pb-4 text-center text-md">{{ t('auth.common.select_email_provider') }}</Paragraph>
    <div class="flex justify-evenly gap-4">
      <Button
        variant="secondary"
        class="flex h-32 w-2/5 flex-col items-center justify-center gap-2 text-brand-650"
        @click="accountType = 'imap'"
      >
        <INolasEmail class="h-12 w-12" /> {{ t('auth.common.add_imap') }}
      </Button>
      <Button
        variant="secondary"
        class="flex h-32 w-2/5 flex-col items-center justify-center gap-2 text-brand-650"
        @click="accountType = 'exchange'"
      >
        <INolasExchange class="h-12 w-12" /> {{ t('auth.common.add_exchange') }}
      </Button>
    </div>
    <Button variant="outline" class="mt-6" @click="emit('go-back')">
      <INolasArrowLeft class="h-5 w-5" /> {{ t('auth.button.go_back') }}
    </Button>
  </div>

  <ImapIntegrationForm v-else-if="accountType === 'imap'" @cancel="onCancel" @success="onSuccess" />
  <ExchangeIntegrationForm v-else-if="accountType === 'exchange'" @cancel="onCancel" @success="onSuccess" />
</template>
