import { AddImapAccount, AddImapAccountRequest } from '@services/auth-api'
import { useMutation, useQueryClient } from '@tanstack/vue-query'
import { Ref } from 'vue'
import { useI18n } from 'vue-i18n'

import { ServiceError } from '@epostbox/shared/errors'
import { toast } from '@epostbox/ui/sonner'

import { emailAccountsQuery } from '@composables/email-integration/use-email-accounts'
import { useUser } from '@composables/use-user'

export function useImapAdd() {
  const { user } = useUser()
  const { t } = useI18n()

  const queryClient = useQueryClient()

  const {
    mutateAsync: addImapAccount,
    error,
    ...mutation
  } = useMutation({
    mutationFn: async (request: AddImapAccountRequest) => {
      const { response, data, error } = await AddImapAccount({
        params: {
          query: {
            workspaceId: user.value!.workspaceId,
          },
        },
        body: request,
      })

      if (!response.ok || !data) {
        throw ServiceError.fromResponse(error)
      }
      return data
    },
    onSuccess: () => {
      toast.success(t('auth.common.imap_added'), { dataE2e: 'imap-add-success-notification' })
      queryClient.invalidateQueries({ queryKey: emailAccountsQuery._def })
    },
    onError: () => {
      toast.error(t('auth.common.imap_not_added'), { dataE2e: 'imap-add-error-notification' })
    },
  })

  return { addImapAccount, error: error as Ref<ServiceError | null>, ...mutation }
}
